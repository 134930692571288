import { AbsoluteCenter } from '@chakra-ui/react';
import React from 'react';

const DisabledOverlay = ({ disabled, children, ...rest }: {
  disabled?: boolean,
  children?: React.ReactNode,
}) => {
  if (!disabled) return null;
  return (
    <AbsoluteCenter
      background="#ffffff55"
      transform="none"
      top="-5px"
      bottom="-5px"
      right="-5px"
      left={{ base: '-5px', lg: 'calc(50% - 5px)' }}
      backdropFilter="blur(5px)"
      zIndex="2"
    >
      <AbsoluteCenter>
        {children}
      </AbsoluteCenter>
    </AbsoluteCenter>
  );
};

export default DisabledOverlay;
