import { strings } from 'business-dashboard/src/i18n';
import React from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';

import PlanCard from './PlanCard';
import { Plan } from './types';

const PlanCardLayout = (
  { plans, onSelect, businessId, isLocalOwnerDashboard }:
  { plans?: Array<Plan>, onSelect: (p: Plan) => void, businessId: string, isLocalOwnerDashboard: boolean },
) => {
  if (!plans) return null;
  if (plans.length === 1) {
    return (
      <SimpleGrid justifyContent="center">
        <PlanCard
          plan={plans[0]}
          key={plans[0].key}
          onSelect={onSelect}
          businessId={businessId}
          isLocalOwnerDashboard={isLocalOwnerDashboard}
        />
      </SimpleGrid>
    );
  }
  return (
    <Flex direction="column" gap="2em">
      <SimpleGrid
        columns={[1, null, 2, null, 2]}
        spacing={8}
      >
        {plans.map(
          plan => <PlanCard
            plan={plan}
            key={plan.key}
            onSelect={onSelect}
            businessId={businessId}
            isLocalOwnerDashboard={isLocalOwnerDashboard}
          />,
        )}
      </SimpleGrid>
      <Text align="right">* Additional locations are an additional $25/month each.</Text>
      <Divider />
      <EnterpriseSection />
    </Flex>
  );
};

const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

const EnterpriseSection = () => {
  return (
    <Flex flexDir="column" align="center" gap="1em">
      <Heading as="h2" size="lg" mb={2}>Enterprise</Heading>
      <Text>
        Need custom feature configurations and support for a shopping centre,
        multi-location business, or national brand?
      </Text>
      <Text>
        Talk to us for details and a demo of GetintheLoop for Enterprise.
      </Text>
      <Spacer />
      <Button colorScheme="blue" as="a" href={`mailto:${supportEmail}`} target="_blank">
        {strings('ui.component.planCardAction.contactUs')}
      </Button>
    </Flex>
  );
};

export default PlanCardLayout;
