import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Stack } from '@chakra-ui/react';

const NavButton = ({
  children,
  to,
  ...props
}: {
  children: React.ReactNode;
  to: string;
}): JSX.Element => {
  return (
    <Button
      color="gray.300"
      _hover={{
        color: 'white',
        textDecoration: 'none',
      }}
      variant="link"
      as={NavLink}
      to={to}
      {...props}
    >
      {children}
    </Button>
  );
};

const NavItems = ({ ...props }): JSX.Element => {
  return (
    <Stack spacing={5} {...props}>
      <NavButton to={`/businesses`}>Businesses</NavButton>
      <NavButton to={`/users`}>Users</NavButton>
      <NavButton to={`/properties`}>Properties</NavButton>
      <NavButton to={`/regions`}>Regions</NavButton>
      <NavButton to={`/addresses`}>Addresses</NavButton>
      <NavButton to={`/categories`}>Categories</NavButton>
      <NavButton to={`/social/posts`}>Social</NavButton>
    </Stack>
  );
};

export default NavItems;
