import React, {
  useEffect,
  useState,
} from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
} from '@chakra-ui/react';
import {
  Redirect,
  useHistory,
} from 'react-router-dom';

import ActionMenu from 'web-react-ui/src/chakra/ActionMenu';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import LocationDetails from 'web-react-ui/src/chakra/LocationDetails';
import Location from 'web-react-ui/src/types/Location.interface';

import client from '../../services/client';
import confirm from '../../services/confirm';
import EditLocationModal from '../locations/EditLocationModal';

const AdminBusinessLocationDetails = ({
  locationId,
  businessId,
}: {
  locationId: string,
  businessId: string
}): JSX.Element => {
  const [location, setLocation] = useState<Location>();
  const [editLocation, setEditLocation] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [redirect, setRedirect] = useState<string>();
  const [externalIds, setExternalIds] = useState<Record<string, string>>();
  const [locationError, setLocationError] = useState<string>();
  const history = useHistory();

  const fetchLocation = () => {
    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .adminDetails();
  };

  const fetchExternalIds = () => {
    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .getExternalPlaceIds();
  };

  useEffect(() => {
    if (businessId && locationId && location) {
      fetchExternalIds().then((data: Record<string, string>) => {
        setExternalIds(data);
      });
    }
  }, [businessId, locationId, location]);

  useEffect(() => {
    if (businessId && locationId) {
      fetchLocation().then((data: Location) => {
        setLocation(data);
      });
    }
  }, [businessId, locationId]);

  // TODO: Tear out all the edit crap from here.
  // Deal with it entirely in the EditLocationModal.
  const handleEditLocation = () => {
    setEditLocation(true);
    setLoadingLocation(true);
    client
      .businesses.for(businessId)
      .locations.for(locationId)
      .editWithLocalization()
      .then((data: any) => { // @todo figure out better typing for this
        setLoadingLocation(false);
      });
  };

  const cancelEditLocation = () => {
    setEditLocation(false);
  };

  // NOTE: This expects the ids in an array of `{ key, value }` elements.
  const saveExternalPlaceIds = (externalPlaceIds: Record<string, string>) => {
    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .setExternalPlaceIds(externalPlaceIds);
  };

  const saveLocationData = (locationFields: Record<string, any>) => client
    .businesses.for(businessId)
    .locations.for(locationId)
    .update(location, locationFields);


  const saveLocation = (newFields: Record<string, any>) => {
    const { externalListings, ...locationFields } = newFields;
    return Promise.all([
      saveExternalPlaceIds(externalListings),
      saveLocationData(locationFields),
    ]).then(([, updatedLocation]) => {
      cancelEditLocation();
      setLocation(updatedLocation);
    }).catch((err: Record<string, string>) => {
      setLocationError(err.message);
    });
  };

  const deleteLocation = () => {
    if (!location) return false;
    if (!confirm(`Are you sure you want to delete ${location.name}?`)) return false;
    setIsBusy(true);
    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .delete()
      .then(() => {
        setIsBusy(false);
        setRedirect(`/businesses/${businessId}/locations`);
      });
  };

  const actions = [
    { label: 'Edit Location', action: handleEditLocation },
  ];
  if (redirect) return <Redirect to={redirect} />;

  return (
    <Box maxW="7xl" mx="auto" p={5}>
      <WaitFor waitFor={location && !isBusy}>
        <Button
          colorScheme="blue"
          variant="link"
          fontSize="xl"
          leftIcon={<ChevronLeftIcon />}
          onClick={() => history.goBack()}
          my={5}
        >
          Back
        </Button>
        <Flex align="center" justify="space-between">
          <Heading>Location Details</Heading>
          <ActionMenu actions={actions} />
        </Flex>
        <EditLocationModal
          businessId={businessId}
          locationId={locationId}
          open={editLocation}
          onCancel={cancelEditLocation}
          onSubmit={saveLocation}
        />
        <LocationDetails
          isLoading={loadingLocation}
          location={location}
          externalIds={externalIds}
        />
        {process.env.REACT_APP_ENV !== 'prod' && (
          <Flex mt={8}>
            <Button colorScheme="red" onClick={deleteLocation} ml="auto">
              Delete Location
            </Button>
          </Flex>
        )}
      </WaitFor>
    </Box>
  );
};

export default AdminBusinessLocationDetails;
