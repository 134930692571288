import {
  Box,
  Flex,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, {
  useMemo,
} from 'react';
import _ from 'lodash';
import useClient from '../../hooks/useClient';
import Profile from '../../interfaces/Profile.interface';
import DisabledOverlay from './DisabledOverlay';
import SettingColor from './SettingColor';
import SettingSelect from './SettingSelect';

const BrandSettings = ({ profile, businessId, disabled, disabledContent }: {
  profile: Profile,
  businessId: string,
  disabled?: boolean,
  disabledContent?: React.ReactNode,
}) => {
  const client = useClient();

  const onChange = useMemo(
    () => async ({ name, value }: { name: string, value: any }) => {
      const body = {};
      _.set(body, name, value);

      await client
        .businesses.for(businessId)
        .social.modifyConfig(body);
    },
    [],
  );

  return (
    <Flex direction="column" gap="1em" position="relative">
      <FormControl display="flex">
        <FormLabel fontSize="lg" w="50%">Primary Brand Color</FormLabel>
        <SettingColor
          name="branding.colors.primary"
          value={profile.config.branding.colors.primary}
          onChange={onChange}
        />
      </FormControl>
      <Flex direction="column" gap="1em" position="relative">
        <FormControl display="flex">
          <FormLabel fontSize="lg" w="50%">Brand Style</FormLabel>
          <SettingSelect
            name="branding.style"
            value={profile.config.branding.style}
            onChange={onChange}
            options={[
              { label: 'Standard', value: 'standard' },
              { label: 'Bold-vibrant', value: 'bold-vibrant' },
              { label: 'Classic-modern', value: 'classic-modern' },
              { label: 'Soft-Light', value: 'soft-light' },
            ]}
            disabled={disabled}
          />
        </FormControl>
        <FormControl display="flex">
          <FormLabel fontSize="lg" w="50%">Brand Voice</FormLabel>
          <SettingSelect
            name="branding.voice"
            value={profile.config.branding.voice}
            onChange={onChange}
            options={[
              { label: 'Standard', value: 'standard' },
              { label: 'Adventurous', value: 'adventurous' },
              { label: 'Casual', value: 'casual' },
              { label: 'Innovative', value: 'innovative' },
              { label: 'Professional', value: 'professional' },
              { label: 'Sophisticated', value: 'sophisticated' },
              { label: 'Empowering', value: 'empowering' },
              { label: 'Warm-Inviting', value: 'warm-inviting' },
            ]}
            disabled={disabled}
          />
        </FormControl>
        <Box mt="1em">
          <DisabledOverlay disabled={disabled}>
            {disabledContent}
          </DisabledOverlay>
        </Box>
      </Flex>
    </Flex>
  );
};

export default BrandSettings;
