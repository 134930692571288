/* eslint-disable arrow-parens */
import {
  AspectRatio,
  Flex,
  Grid,
  Heading,
  HStack,
  Box,
  Image,
  LinkBox,
  Select,
  Text,
  Link,
} from '@chakra-ui/react';

import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import StatusBadge from 'business-dashboard/src/components/businesses/social/components/StatusBadge';
import Post from 'business-dashboard/src/components/businesses/social/interfaces/Post.interface';
import React from 'react';

import usePagedList from 'shared/src/hooks/usePagedList';
import {
  transport,
  useQuerystringParam,
} from 'shared/src/hooks/useQuerystringParam';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import SubNav from 'web-react-ui/src/navigation/SubNav';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';
import { Empty } from 'web-react-ui/src/chakra/TableList/TableList';
import NetworkIcon from 'shared/src/components/NetworkIcon';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import client from '../../services/client';
import SkeletonSocialPostCard from './SkeletonSocialPostCard';
import SocialDetailsModal from './SocialDetailsModal';
import SocialPosts from './SocialPosts';
import SocialConnectionErrors from './SocialConnectionErrors';

const NavMenuItem = (props) => <SubNav.Item as={NavLink} {...props} />;
const baseUrl = '/social';
const fetchPosts = async ({
  state,
  startDate,
  endDate,
}: {
  state: string;
  startDate?: string;
  endDate?: string;
}): Promise<{ items: Array<Post> } | undefined> => {
  const params: { state: string; rangeStart?: string; rangeEnd?: string } = {
    state,
  };
  if (startDate && endDate) {
    // Round-trip dates so they're the right format for our API
    params.rangeStart = new Date(startDate).toISOString();
    params.rangeEnd = new Date(endDate).toISOString();
  }
  return client.social.posts.listAll(params);
};

const formattedDate = (date: Date) =>
  `${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date
    .getUTCMinutes()
    .toString()
    .padStart(2, '0')} ${String(date.getUTCMonth() + 1).padStart(
    2,
    '0'
  )}/${String(date.getUTCDate()).padStart(2, '0')}/${date.getUTCFullYear()}`;

const PostItem = ({ post }: { post: Post }) => {
  const businessId = post.relatedEntities.find((e) => e.type === 'business')
    ?.id;
  const { scheduledDate: date } = post;
  const scheduledDate = new Date(date);

  const [queryString] = useQuerystring();
  const qsp = new URLSearchParams(queryString);
  qsp.set('businessId', businessId);
  qsp.set('postId', post.id);
  const target = `/social?${qsp.toString()}`;

  return (
    <LinkBox
      as="article"
      borderWidth="1px"
      rounded="lg"
      maxW="30rem"
      w="100%"
      h="100%"
      className="_card _postItem"
      placeContent="center"
      boxShadow="sm"
      _hover={{
        cursor: 'pointer',
        backgroundColor: 'gray.50',
      }}
    >
      <Link to={target}>
        <Flex
          direction="column"
          borderColor="red.200"
          border="xl"
          px="4"
          pb="8"
          w="100%"
          h="100%"
          className="_card"
        >
          <HStack className="_postItem-header" w="fit-content" py="2">
            <NetworkIcon id={post?.socialNetwork} fontSize="2em" />
            <Text w="100%" fontWeight="medium" textColor="gray.600">
              {post.campaignName}
            </Text>
          </HStack>
          <AspectRatio
            h="auto"
            ratio={post.contentType === 'story' ? 9 / 16 : 1}
            rounded="md"
            overflow="hidden"
          >
            <Image
              src={
                post?.output?.media?.[0] ??
                'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg'
              }
              fill="#4267B2"
              alt="logo"
              boxShadow="sm"
            />
          </AspectRatio>
          <HStack py="4" overflow="hidden">
            <StatusBadge status={post.displayState} w="max-content" />
            <Text
              whiteSpace="nowrap"
              fontWeight="medium"
              display="inline-block"
              w="80ch"
              textAlign="right"
            >
              {formattedDate(scheduledDate)}
            </Text>
          </HStack>
          <Text fontWeight="normal" textColor="gray.600" marginBottom="auto">
            {post.output.message}
          </Text>
        </Flex>
      </Link>
    </LinkBox>
  );
};

const AdminSocialView = ({ children }) => {
  const [statusSearch, setStatusSearch] = useQuerystringParam(
    'status',
    'scheduled',
    {
      squash: false,
    }
  );
  const [startDate, setStartDate] = useQuerystringParam('start', undefined, {
    transport: transport.dateString,
    squash: true,
  });
  const [endDate, setEndDate] = useQuerystringParam('end', undefined, {
    transport: transport.dateString,
    squash: true,
  });

  const postList = usePagedList(fetchPosts, {
    state: statusSearch,
    startDate,
    endDate,
  });
  return (
    <Flex
      gap="1em"
      direction="column"
      px={[2, 0]}
      my={10}
      mx={{ base: '2rem', lg: '10vw' }}
      className="_socialCardContainer"
    >
      <Heading
        style={{ gridColumnStart: 1, gridColumnEnd: -1, placeSelf: 'start' }}
      >
        Social AI
      </Heading>
      <SubNav>
        <NavMenuItem to={`${baseUrl}/posts`} name="content-review" />
        <NavMenuItem to={`${baseUrl}/connectivity`} name="connection-errors" />
      </SubNav>
      {children}
    </Flex>
  );
};

export default AdminSocialView;
