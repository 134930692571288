import {
  chakra,
  useToast,
  Input,
  InputGroup,
  InputRightElement,
  Box,
} from '@chakra-ui/react';
import React, {
  useState,
} from 'react';
import Spinner from 'web-react-ui/src/chakra/Spinner';
import {
  compose,
  required,
  hexColor,
} from 'web-react-ui/src/reactFinalForm/validators';
import useRequest from '../../hooks/useRequest';

type OnChangeFunction = ({ name, value }: { name: string, value: string }) => Promise<void>;

const SettingColor = ({
  name, value, onChange, disabled,
}: {
  name: string,
  value: any,
  onChange: OnChangeFunction,
  disabled?: boolean
}) => {
  const [actualState, setActualState] = useState(value);
  const [selectState, setSelectState] = useState(value);

  const colorInput = React.useRef(null);

  const error = hexColor(selectState);

  const toast = useToast();

  const onChangeRequest = useRequest(
    async (): Promise<void> => {
      const newValue = selectState;

      setSelectState(newValue);

      if (newValue === actualState) return;

      if (compose(required, hexColor)(selectState)) {
        return;
      }

      try {
        await onChange({ name, value: newValue.toLowerCase() });
      } catch (err) {
        // revert to initial value
        setSelectState(actualState);
        toast({
          title: `Unable to update ${name} setting`,
          description: err.message || err,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setActualState(newValue);
    },
    undefined,
    {
      discardError: true,
    },
  );

  return (
    <Spinner loading={onChangeRequest.loading} flex="1">
      <chakra.div flex="1">
        <InputGroup>
          <Input
            value={selectState}
            onChange={e => setSelectState(e.target.value)}
            onBlur={onChangeRequest.run}
            placeholder="#ff0099"
            ref={colorInput}
            isDisabled={disabled}
          />
          <InputRightElement>
            <Box p={1} w="3em" h="100%">
              <label>
                <Box
                  w="100%"
                  h="100%"
                  background={error ? undefined : selectState}
                  border="1px dashed silver"
                />
              </label>
            </Box>
          </InputRightElement>
        </InputGroup>
      </chakra.div>
    </Spinner>
  );
};

export default SettingColor;
