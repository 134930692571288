/* eslint-disable arrow-parens */
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import UserProfile from '../components/admin/users/UserProfile';
import EditUser from '../components/admin/users/EditUser';
import AdminAddBusinessContainer from '../components/admin/AdminAddBusinessContainer';
import AdminAddresses from '../components/admin/AdminAddresses';
import AdminBusinessesListView from '../components/admin/AdminBusinessesListView';
import AdminCategories from '../components/admin/AdminCategories';
import AdminCategoryEdit from '../components/admin/AdminCategoryEdit';
import AdminLanding from '../components/admin/AdminLanding';
import AdminProperties from '../components/admin/AdminProperties';
import SocialConnectionErrors from '../components/social/SocialConnectionErrors';
import AdminPropertyDetails from '../components/admin/AdminPropertyDetails';
import AdminRegionsCreate from '../components/admin/AdminRegionCreate';
import AdminRegions from '../components/admin/AdminRegions';
import AdminUsers from '../components/admin/AdminUsers';
import CheckBusinessContainer from '../components/businesses/create/CheckBusinessContainer';
import PostCreateBusinessContainer from '../components/businesses/create/postCreate/PostCreateBusinessContainer';
import CompareView from '../components/compare/CompareView';
import PropertyEdit from '../components/property/PropertyEdit';
import AdminSocialView from '../components/social/AdminSocialView';
import SocialPostDetails from '../components/social/SocialPostDetails';
import SocialPosts from '../components/social/SocialPosts';
import AdminBusinessDetailsRoutes from './AdminBusinessDetailsRoutes';

const renderAdminBusinessDetailsRoutes = (props) => (
  <AdminBusinessDetailsRoutes
    businessId={props.match.params.id}
    baseUrl={props.match.url}
    {...props}
  />
);
renderAdminBusinessDetailsRoutes.propTypes = {
  match: PropTypes.object,
};

const AdminRoutes = (props) => {
  const { isLoggedIn, isPending, location } = props;

  if (!isLoggedIn && !isPending) {
    const redirectTo = encodeURIComponent(location.pathname + location.search);
    return <Redirect to={`/login?redirect_to=${redirectTo}`} />;
  }

  return (
    <div className="_routes">
      <Switch>
        <Route
          path={`/businesses/add/check`}
          component={CheckBusinessContainer}
        />
        <Route
          path={`/businesses/add/complete/:businessId`}
          component={PostCreateBusinessContainer}
        />
        <Route path={`/businesses/add`} component={AdminAddBusinessContainer} />
        <Route
          path={`/businesses/:id`}
          render={renderAdminBusinessDetailsRoutes}
        />
        <Route path={`/businesses`} component={AdminBusinessesListView} />
        <Route exact path={`/users`} component={AdminUsers} />
        <Route exact path="/users/:userId" component={UserProfile} />
        <Route exact path="/users/:userId/edit" component={EditUser} />
        <Route path={`/properties`} exact component={AdminProperties} />
        <Route
          path={`/properties/:id`}
          exact
          render={(routeProps) => (
            <AdminPropertyDetails
              propertyId={routeProps.match.params.id}
              {...routeProps}
            />
          )}
        />
        <Route
          path={`/properties/:id/edit`}
          exact
          render={(routeProps) => (
            <PropertyEdit
              propertyId={routeProps.match.params.id}
              redirectTo={`properties/${routeProps.match.params.id}`}
              admin
            />
          )}
        />
        <Route path={`/regions`} exact component={AdminRegions} />
        <Route
          path={`/regions/add`}
          exact
          render={({ history }) => <AdminRegionsCreate history={history} />}
        />
        <Route path={`/addresses`} exact component={AdminAddresses} />
        <Route path={`/categories`} exact component={AdminCategories} />
        <Route exact path={`/categories/add`}>
          <AdminCategoryEdit create={true} />
        </Route>
        <Route
          path={`/categories/:id`}
          exact
          render={(routeProps) => (
            <AdminCategoryEdit categoryId={routeProps.match.params.id} />
          )}
        />
        <Route
          path={'/social/:businessId/:postId'}
          exact
          component={SocialPostDetails}
        />
        <Route
          path={'/social/:businessId/features/post/:postId'}
          exact
          component={SocialPostDetails}
        />
        <Route
          exact
          path={'/social/posts'}
          render={() => (
            <AdminSocialView>
              <SocialPosts />
            </AdminSocialView>
          )}
        />
        <Route
          path={'/social/connectivity'}
          exact
          render={() => (
            <AdminSocialView>
              <SocialConnectionErrors />
            </AdminSocialView>
          )}
        />
        <Route path="/social" render={() => <Redirect to="/social/posts" />} />
        <Route path={'/ai/evaluations/:id'} exact component={CompareView} />

        <Route component={AdminLanding} />
      </Switch>
    </div>
  );
};

AdminRoutes.propTypes = {
  match: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const { auth } = state;
  const { isLoggedIn, isPending } = auth || {};

  return {
    isLoggedIn,
    isPending,
  };
}

export default connect(mapStateToProps, null)(AdminRoutes);
