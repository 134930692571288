import {
  Box,
  Heading,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import View from 'web-react-ui/src/components/layout/View';
import EditLocation from 'shared/src/components/locations/EditLocation';
import ExternalListingsField from 'web-react-ui/src/components/locations/ExternalListingsField';

import businessesModule from '../../modules/businesses';
import client from '../../services/client';


const EditLocationModal = ({
  businessId,
  locationId,
  onSubmit,
  onCancel,
  open,
}: {
  businessId: string,
  locationId: string,
  onSubmit: (fields: { [key: string]: any; }) => any,
  onCancel: () => void,
  open: boolean,
}) => {
  const onUpdate = useCallback(
    (ignored, updates) => {
      return onSubmit(updates);
    },
    [],
  );

  const fetchLocation = useCallback(
    async () => {
      const [location, externalListings] = await Promise.all([
        client
          .businesses.for(businessId)
          .locations.for(locationId)
          .editWithLocalization(),
        client
          .businesses.for(businessId)
          .locations.for(locationId)
          .getExternalPlaceIds(),
      ]);

      return {
        ...location,
        externalListings
      };
    },
    [],
  );

  return (
    <BigModal open={open}>
      <BigModal.Contents>
        <BigModal.Header className="p1">
          <PageBackButton onClick={onCancel}>Back</PageBackButton>
        </BigModal.Header>
        <BigModal.Body>
          <View>
            <EditLocation fetchLocation={fetchLocation} onUpdate={onUpdate}>
              <Box gridColumn="1">
                <Heading size="md" mb={2}>External Listing IDs</Heading>
                <AddExternalListingsField />
              </Box>
            </EditLocation>
          </View>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

const AddExternalListingsField = () => {
  // Hack to get the location details to pass to ExternalListingsField...
  const formState = useFormState();
  const location = formState.values;

  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  return (
    <ExternalListingsField
      name="externalListings"
      context={{ location, name: business.name }}
    />
  );
};

export default EditLocationModal;
