/* eslint-disable arrow-parens */
import {
  Flex,
  Heading,
  HStack,
  SkeletonCircle,
  SkeletonText,
  Table,
  TableContainer,
  Link,
  LinkOverlay,
  LinkBox,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';
import usePagedList from 'shared/src/hooks/usePagedList';
import React from 'react';
import NetworkIcon from 'shared/src/components/NetworkIcon';
import client from '../../services/client';

type SocialProfileProps = {
  branding?: undefined;
  created: string;
  enabled: boolean;
  health: {
    facebookPost?: {
      healthy: boolean;
      lastPostId: string;
    };
    facebookStory?: {
      healthy: boolean;
      lastPostId: string;
    };
    instagramPost?: {
      healthy: boolean;
      lastPostId: string;
    };
    instagramStory?: {
      healthy: boolean;
      lastPostId: string;
    };
  };
  healthy: string;
  id: string;
  isLinked: boolean;
  name: string;
  socialAccounts: Array<{
    type: string;
    linked: boolean;
  }>;
  timezone: string;
};

const fetchUnhealthyProfiles = async () => {
  return client.social.list({ healthy: false });
};

function checkForProfileErrors(profile: SocialProfileProps) {
  const errors = [0, 0, 0, 0];
  if (profile.socialAccounts.length === 0) errors[0] = 1;
  profile.socialAccounts?.some((acc) => {
    if (!acc.linked) {
      errors[1] = 1;
      return true;
    }
    return null;
  });

  Object.values(profile.health).some((el) => {
    if (!el.healthy) {
      errors[2] = 1;
      return true;
    }
    return null;
  });
  if (errors.reduce((total, curr) => total + curr, 0) > 1) errors[3] = 1;
  return errors;
}

const SocialConnectionErrors = () => {
  const postList = usePagedList(fetchUnhealthyProfiles, {});
  return (
    <TableContainer
      className="_tableContainer"
      alignSelf="start"
      w="100%"
      display="flex"
    >
      <Flex justifyContent="center" w="100%" direction="column" gap="2rem">
        <Table variant="simple" size="lg">
          <Heading marginRight="auto" py="2rem" size="lg">
            Social AI profiles with account issues
          </Heading>
          <Tbody>
            {postList.settled &&
              postList.items.map((profile, key) => (
                <NewErrorRow profile={profile} key={key} />
              ))}
            {postList.loading &&
              Array(8)
                .fill('')
                .map((_, id) => <SkeletonNewErrorRow key={id} />)}
            {postList.empty && (
              <Tr>
                <Td>
                  <Heading as="h4" size="md" py="2rem" textAlign="center">
                    No Social AI profiles currently have errors 🎉
                  </Heading>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <AutoPagination pagedList={postList} />
      </Flex>
    </TableContainer>
  );
};

const SkeletonNewErrorRow = () => {
  return (
    <Tr opacity={0.7} w="100%" h="5rem">
      <Td w="min-content" pl="0">
        <SkeletonText mt="2" noOfLines={2} spacing="2" w="25%" />
      </Td>
      <Td>
        <HStack minWidth="20%">
          <SkeletonCircle size="10" marginLeft="auto" />
          <SkeletonCircle size="10" />
          <SkeletonText
            pl="1rem"
            noOfLines={2}
            spacing="2"
            w="fit-content"
            minWidth="20ch"
          />
        </HStack>
      </Td>
    </Tr>
  );
};

const NewErrorRow = ({ profile }: { profile: SocialProfileProps }) => {
  const incidents = checkForProfileErrors(profile);
  const businessStrIx = [profile.id.indexOf('b'), profile.id.indexOf('-')];
  const businessId = profile.id.slice(businessStrIx[1] + 1);

  const listOfUnhealthySocialMediaProfiles = Object.entries(profile.health)
    .filter(([_, v]) => !v.healthy)
    .map((key) => key[0].split('-')[0]);

  const socialIcons = Array.from(
    new Set(listOfUnhealthySocialMediaProfiles)
  ).sort();

  return (
    <Tr fontSize="xl" fontWeight="400" justifyContent="space-between">
      <Td display="flex" flexDirection="row" pl="0">
        <LinkBox>
          <LinkOverlay as={Link} href={`/businesses/${businessId}/social-ai`}>
            <Text w="40ch" fontSize="xl" fontWeight="medium">
              {profile.name}
            </Text>
          </LinkOverlay>
        </LinkBox>
        <Flex
          as="div"
          w="64px"
          direction="row"
          marginLeft="auto"
          display="inline-flex"
          className="_socialConnectionErrorsIcons"
          alignItems="end"
        >
          {socialIcons.sort().map((social, id) => (
            <NetworkIcon id={social} key={id} fontSize="1.5em" />
          ))}
        </Flex>
        <RenderErrorMsg incidents={incidents} />
      </Td>
    </Tr>
  );
};

const RenderErrorMsg = ({ incidents }: { incidents: Number[] }) => {
  if (incidents[3]) return <Text w="25ch">Multiple account errors</Text>;
  if (incidents[2]) {
    return <Text w="25ch">Errors posting or loading stats</Text>;
  }
  if (incidents[1]) return <Text w="25ch">Connection revoked</Text>;
  if (incidents[0]) return <Text w="25ch">No social accounts connected</Text>;
  return null;
};

export default SocialConnectionErrors;
